import { Component, computed, ElementRef, inject, input, viewChild } from '@angular/core';
import { CollectionItemMediaComponent } from './collection-item-media.component';
import { CollectionItemService, MediaItem } from './collection-item.service';
import Sortable, { SortableEvent } from 'sortablejs';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ImageModalComponent, ImageModalProps } from 'src/app/shared/components/image-modal/image-modal.component';
import { DialogService } from 'primeng/dynamicdialog';

@Component({
    selector: 'app-collection-item-media-list',
    templateUrl: 'collection-item-media-list.component.html',
    standalone: true,
    imports: [
        CollectionItemMediaComponent,
        ProgressSpinnerModule
    ],
    providers: [
        DialogService
    ]
})

export class CollectionItemMediaListComponent {

    collectionItemService = inject(CollectionItemService);
    dialogService = inject(DialogService);

    items = input<MediaItem[]>();

    list = viewChild<ElementRef>('list');

    mediaLoading = this.collectionItemService.mediaLoading;
    sortedItems = computed(() => {
        const items = this.items();
        return items.sort((a, b) => a.sort - b.sort);
    });

    sortable: Sortable;

    ngAfterViewInit(): void {
        this.sortable = new Sortable(this.list().nativeElement!, {
            sort: true,
            handle: '.sort-handle',
            dataIdAttr: 'data-item',
            setData: function (/** DataTransfer */dataTransfer, /** HTMLElement*/dragEl) {
                dataTransfer.setData('id', dragEl.dataset.item); // `dataTransfer` object of HTML5 DragEvent
            },
            onEnd: (e: SortableEvent) => this.handleSort(e)
        });
    }
    
    private handleSort(e: SortableEvent) {
        const sortedCopy = [...this.sortedItems()];
        const { oldIndex, newIndex } = e;
        const movedItem = sortedCopy.splice(oldIndex, 1)[0];
        sortedCopy.splice(newIndex, 0, movedItem);
        console.log(sortedCopy);
        const newSortedItems = sortedCopy.map((item, index) => {
            console.log(index);
            
            return {
                ...item,
                sort: index
            }
        });
        
        this.collectionItemService.updateMediaItemsSort(newSortedItems);
    }

    public clickImage($event: Event, selectedImage: MediaItem) {
        $event.preventDefault();
        $event.stopPropagation();

        const images = this.items().filter(i => {
            return i.type.split('/')[0] === 'image';
        });

        const imageProps: ImageModalProps = {
            images: images.map(image => ({ url: image.url, name: image.name })),
            index: images.findIndex(image => image.id === selectedImage.id)
        };

        this.dialogService.open(ImageModalComponent, {
            data: imageProps,
            header: 'Item media',
            width: '75vw',
            height: '90vh',
            modal: true,
            breakpoints: {
                '960px': '75vw',
                '640px': '90vw'
            },
            contentStyle: { 'padding': '0px' }
        });
    }
}