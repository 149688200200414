import { Component, EventEmitter, OnDestroy, OnInit, Output, computed, inject, input, signal, viewChild } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Attribute, AttributeConditionRule, AttributeConditions, Question } from './form.types';
import { FormQuestionComponent } from './form-question.component';
import { CoreoExpressionEvaluator } from '@natural-apptitude/coreo-expressions/dist/esm';
import { debounce } from 'lodash';
import { RuleEngine } from '@natural-apptitude/coreo-conditions';
import { ApiService } from 'src/app/core';
import { Observable, Subscription, map, take, combineLatest, of } from 'rxjs';
import { NewChildRecord, RecordAttachment, RecordUpdateData } from '../records.service';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { FormCollectionsService } from './form-collections.service';
import { formatISO } from 'date-fns';
import { Feature, Geometry } from 'geojson';
import { ConfigGeometryTypes, RecordMapComponent } from '../record-detail-page/record-map/record-map.component';
import { TooltipModule } from 'primeng/tooltip';

export interface RecordData {
    [path: string]: any;
}

interface CollectionItemMediaItem {
    type: string;
    url: string;
}

interface CollectionItem {
    key: string;
    value: string;
    data: any;
    mediaItems: CollectionItemMediaItem[];
}
interface CollectionResponse {
    id: number;
    items: CollectionItem[];
}

type AttributeWithCollection = Attribute & {
    collection: CollectionResponse;
}
interface FormDataRecordResponse {
    survey: {
        attributes: AttributeWithCollection[];
    }
    project: {
        geometry: Geometry;
    }
}

interface FormDataSurveyResponse {
    geometry: Geometry;
    surveys: {
        attributes: AttributeWithCollection[];
    }[];
    parent: {
        geometry: Geometry;
        surveys: {
            attributes: AttributeWithCollection[];
        }[]
    }
}

interface FormData {
    geometry: Geometry;
    collections: CollectionResponse[];
    attributes: Attribute[];
}

interface UserResponse {
    displayName: string;
    username: string;
    id: number;
}

interface RecordDataResponse {
    geometry: Geometry;
    data: RecordData;
    attachments: RecordAttachment[];
    associates: {
        association: {
            id: number;
            path: string;
        };
        record: {
            id: number;
        };
    }[];
}

@Component({
    selector: 'app-record-form',
    templateUrl: './record-form.component.html',
    imports: [
        ReactiveFormsModule,
        ProgressSpinnerModule,
        TooltipModule,
        FormQuestionComponent,
        RecordMapComponent
    ],
    standalone: true,
    providers: [
        FormCollectionsService
    ]
})

export class RecordFormComponent implements OnInit, OnDestroy {

    private apiService = inject(ApiService);
    private formCollectionsService = inject(FormCollectionsService);

    projectId = input.required<number>();
    surveyId = input.required<number>();
    recordId = input<number>();
    isEdit = input<boolean>(false);
    mapStyleName = input<string>()

    @Output() onMapStyleChanged: EventEmitter<string> = new EventEmitter();

    recordMap = viewChild<RecordMapComponent>('recordMap');

    questions = signal<Question[]>([]);
    form = signal<FormGroup | null>(null);
    formDirty = signal(false);
    data = signal<RecordData | null>(null); // Original record data
    user = signal<UserResponse | null>(null);

    /** Old style attachments will be in the record data object and also in attachments but with an id: 0 */
    oldAttachments = signal<{ attributeId: number; path: string; id: number; url: string }[]>([]);

    attributes = signal<Attribute[]>([]);
    collections = signal<CollectionResponse[]>([]);
    formHasExpression = signal<boolean>(false);
    formHasCondition = signal<boolean>(false);

    recordGeometry = signal<Geometry | null>(null);
    hasGeometryQuestion = signal(false);
    geometryQuestionData = signal<{
        text: string;
        description: string;
        help: string;
        required: boolean;
        config: any;
    } | null>(null);
    allowedGeometryTypes = signal<ConfigGeometryTypes[]>([]);
    enforceBounds = signal(false);
    projectBounds = signal<Geometry | null>(null);

    geometryTitle = computed(() => {
        const data = this.geometryQuestionData();
        return data.text;
    });
    geometryDescription = computed(() => {
        const data = this.geometryQuestionData();
        return data.description;
    });
    geometryHelp = computed(() => {
        const data = this.geometryQuestionData();
        return data.help;
    });
    geometryRequired = computed(() => {
        const data = this.geometryQuestionData();
        return data.required;
    });

    feature = computed<Feature>(() => {
        const geometry = this.recordGeometry();
        if (geometry) {
            return { type: 'Feature', geometry, properties: {} };
        } else {
            return null;
        }
    });

    geometryTouched = computed(() => {
        const touched = this.recordMap()?.touched();
        return touched;
    });

    formReady = computed(() => {
        const form = this.form();
        const questions = this.questions();
        return !!form && !!questions;
    });

    private formDataSubscription: Subscription;
    private formChangeSubscription: Subscription;
    private expressionEvaluator: CoreoExpressionEvaluator;
    private getExpressionValues: () => any;

    readonly attributesFragment = `fragment attributeFields on Attribute{ 
        id
        projectId
        meta
        uuid
        order
        path
        type
        label
        collectionId
        filterable
        questionType
        config
        text
        description
        help
        conditions
        sectionIdx
        surveyId
        associatedSurveyId
        required
        visible
        parentCollectionId
        collection{
            id,
            items{
                key
                value
                data
                mediaItems{
                    type
                    url
                }
            }
        }
    }`;

    ngOnInit() {
        const formObservables = combineLatest([
            this.getFormData().pipe(take(1)),
            this.getViewer().pipe(take(1)),
            this.getRecordData().pipe(take(1))
        ]);

        this.formDataSubscription = formObservables.subscribe(([project, viewer, record]) => {
            const { attributes, collections, geometry } = project;

            this.formCollectionsService.setCollections(collections);
            this.attributes.set(attributes);
            this.collections.set(collections);
            this.user.set(viewer);
            this.formHasExpression.set(attributes.some(a => a.questionType === 'expression'));
            this.formHasCondition.set(attributes.some(a => !!a.conditions));

            const geometryQuestion = attributes.find(a => a.questionType === 'geometry');
            if (!!geometryQuestion) {
                this.hasGeometryQuestion.set(true);
                this.geometryQuestionData.set({
                    text: geometryQuestion.text,
                    description: geometryQuestion.description,
                    help: geometryQuestion.help,
                    required: geometryQuestion.required,
                    config: geometryQuestion.config,
                });
                this.allowedGeometryTypes.set(geometryQuestion.config?.types ?? ['point']);
                if (geometryQuestion.config?.enforceBounds) {
                    this.enforceBounds.set(geometryQuestion.config.enforceBounds);
                }
                this.projectBounds.set(geometry);
            }

            if (record) {
                this.recordGeometry.set(record.geometry);
                this.formatRecordData(record, attributes);
            }

            this.buildForm();
        });
    }

    ngOnDestroy(): void {
        if (!!this.formDataSubscription) {
            this.formDataSubscription.unsubscribe();
        }
        if (this.formChangeSubscription) {
            this.formChangeSubscription.unsubscribe();
        }
    }

    private getFormData(): Observable<FormData> {
        return this.recordId() ? this.getFormDataFromRecord() : this.getFormDataFromSurvey();
    }

    private getFormDataFromSurvey(): Observable<FormData> {
        const query = `query getFormDataFromSurvey($projectId: Int!, $surveyId: Int!){
            project(id: $projectId){
                geometry{
                    type
                    coordinates
                }
                surveys(where: { id: $surveyId }){
                    attributes{ ...attributeFields }
                }
                parent{
                    surveys(where: { id: $surveyId }){
                        attributes{ ...attributeFields }
                    } 
                }
            }
        }${this.attributesFragment}`;
        return this.apiService.graphql<{ project: FormDataSurveyResponse }>(query, { projectId: this.projectId(), surveyId: this.surveyId() }).pipe(
            map(res => {
                const survey = res.project.surveys?.[0] ?? res.project.parent?.surveys?.[0];
                const geometry = res.project.geometry ?? res.project.parent?.geometry;

                const collections = survey.attributes.filter(a => !!a.collection).map(a => a.collection);
                const attributes = survey.attributes;

                return {
                    collections,
                    attributes,
                    geometry
                }
            })
        );
    }

    private getFormDataFromRecord(): Observable<FormData> {
        const query = `query getFormData($recordId: Int!){
            record(id: $recordId){
                survey{
                    attributes{...attributeFields}
                }
                project{
                    geometry{
                        type
                        coordinates
                    }
                }
            }
        }${this.attributesFragment}`;

        return this.apiService.graphql<{ record: FormDataRecordResponse }>(query, { recordId: this.recordId() }).pipe(
            map(res => {
                const { survey, project } = res.record;
                const collections = survey.attributes.filter(a => !!a.collection).map(a => a.collection);
                const attributes = survey.attributes;
                const geometry = project.geometry;
                return {
                    collections,
                    attributes,
                    geometry
                }
            })
        );
    }

    private getViewer(): Observable<UserResponse> {
        const query = `query getViewerData{
            viewer{
                id
                displayName
                username
            }
        }`;

        return this.apiService.graphql<{ viewer: UserResponse; }>(query).pipe(
            map(res => res.viewer)
        );
    }

    private getRecordData(): Observable<RecordDataResponse | null> {
        const query = `query getRecordData($recordId: Int!){
            record(id: $recordId){
                geometry{
                    type
                    coordinates
                }
                data
                attachments{
                    id
                    url
                    mimeType
                    attributeId
                    projectId
                }
                associates{
                    association{
                        id
                        path
                    }
                    record{
                        id
                    }
                }
            }
        }`;

        if (this.recordId()) {
            return this.apiService.graphql<{ record: RecordDataResponse }>(query, { recordId: this.recordId() }).pipe(
                map(res => res.record)
            );
        } else {
            return of(null);
        }
    }

    private formatRecordData(record: RecordDataResponse, attributes: Attribute[]) {
        const { associates, attachments, data } = record;
        
        const formData = attributes.map(attribute => {
            let value = data[attribute.path] ?? null;
            /** add attachments to the data object */
            if (attribute.type === 'media' || attribute.type === 'attachment') {
                /** If the data object contains media/attachments these are old style attachments so keep track of them */
                const paths = Object.keys(data);
                if (paths.includes(attribute.path)) {
                    this.oldAttachments.update(val => [...val, { attributeId: attribute.id, path: attribute.path, id: 0, url: data[attribute.path] ?? null } ]);
                }

                const media = attachments.filter(attachment => attachment.attributeId === attribute.id);
                if (media.length > 0) {
                    value = media;
                } else {
                    value = null;
                }
            }
            /** add associates to the data object */
            const hasAssociate: boolean = !!associates.find(a => a.association.path === attribute.path);
            if (hasAssociate) {
                const records = associates.filter(a => a.association.path === attribute.path).map(a => a.record.id);
                value = records;
            }

            return {
                [attribute.path]: value
            }
        });

        this.data.set(Object.assign({}, ...formData));
    }

    private async buildForm() {
        this.questionsToFormGroup();
        // Calculated fields/Expressions
        if (this.formHasExpression()) {
            this.setExpressionEvaluator();
            await this.getExpressionValues();
        }
        // Conditions
        if (this.formHasCondition()) {
            this.evaluateQuestions();
        }

        this.formChangeSubscription = this.form().valueChanges
            .subscribe(async (_) => {
                this.formDirty.set(true);
                
                if (this.formHasExpression()) {
                    await this.getExpressionValues();
                }
                if (this.formHasCondition()) {
                    this.evaluateQuestions();
                }
            });
    }

    private questionsToFormGroup() {
        const questions = this.attributes().filter(attribute => {
            /* don't show these attributes **/
            return (
                attribute.type !== 'coordinatetransform' &&
                attribute.type !== 'rgeolocation' &&
                attribute.type !== 'geometryquery' &&
                attribute.questionType !== 'geometry'
            );
        }).map(attribute => {

            const getValue = (attribute: Attribute) => {
                /** If it's a new form then then data will be null or undefined */
                const value = !!this.data() ? this.data()[attribute.path] : null;
                /** if any of these are null or undefined set as empty array */
                if (
                    attribute.type === 'multiselect' ||
                    attribute.type === 'attachment' ||
                    attribute.type === 'media' ||
                    attribute.questionType === 'association' ||
                    attribute.questionType === 'child'
                ) {
                    return value ?? [];
                }
                /** if slider check for value and if none use default */
                if (attribute.questionType === 'slider') {
                    const min = attribute.config.min;
                    const max = attribute.config.max;
                    let defaultValue = parseFloat(attribute.config?.defaultValue) || 0;
                    if (defaultValue < min) {
                        defaultValue = min;
                    } else if (defaultValue > max) {
                        defaultValue = max;
                    }
                    return value ?? defaultValue;
                }
                /** If date/datetime and auto then fill it in */
                if ((attribute.type === 'date' || attribute.type === 'datetime') && attribute.config?.auto && !value) {
                    switch (attribute.type) {
                        case 'datetime': {
                            const dateString = formatISO(new Date());
                            return dateString;
                        }
                        case 'date': {
                            const dateString = formatISO(new Date(), { representation: 'date' });
                            return dateString
                        }
                        default: {
                            console.error('DO NOT KNOW HOW TO AUTO THIS TYPE');
                        }
                    }
                }

                return value ?? null;
            }

            /** Make sure we use this projectId as the attribute projectId will be of the parent project if this is a child clone */
            const q = { ...attribute, ...{ projectId: this.projectId() }, ...{ value: getValue(attribute) } };

            return new Question(q);
        }).sort((a, b) => a.order - b.order);

        const group: any = {};

        const getValidators = (question: Question) => {
            const validators = [];
            if (question.required) {
                validators.push(Validators.required);
            }
            if (question.config?.format) {
                validators.push(Validators.pattern(new RegExp(question.config.format)));
            }
            /** Ignore slider for min - min in config is used to set the slider number */
            if (question.questionType !== 'slider' && question.config?.min && question.config?.min > 0) {
                validators.push(Validators.required);
                validators.push(Validators.minLength(question.config.min));
            }
            if (question.type === 'email') {
                validators.push(Validators.email);
            }
            if (question.type === 'url') {
                validators.push(Validators.pattern(new RegExp('^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$')));
            }
            return validators;
        }

        this.questions.set(questions);

        this.questions().forEach(question => {
            group[question.path] = new FormControl(question.value, getValidators(question));
            this.form.set(new FormGroup(group));
        });
    }

    private setExpressionEvaluator() {
        const itemResolver = (collectionId, key) => {
            const collection = this.collections().find(c => c.id === collectionId);
            if (typeof collection === 'undefined') {
                return null;
            }
            return collection.items.find(a => a.key === key);
        }

        this.expressionEvaluator = new CoreoExpressionEvaluator(this.attributes().map(attr => ({ ...attr })), itemResolver, this.user);

        const order = this.expressionEvaluator.getExpressionEvaluationOrder().sort((a, b) => a.order - b.order);

        this.getExpressionValues = debounce(async () => {
            for (const a of order) {
                this.expressionEvaluator.setRecord({ data: { ...this.form().getRawValue() } });
                this.expressionEvaluator.setExpressionAttribute(a);
                try {
                    const value = await this.expressionEvaluator.evaluateExpression();
                    // console.log('expressionEvaluator', a.path, value);

                    if (value !== this.form().getRawValue()[a.path]) {
                        this.form().controls[a.path].setValue(value, { emitEvent: false });
                    }
                } catch (e) {
                    console.log('Error', e);
                }
            }
        }, 100, { leading: true });
    }

    private evaluateRule(target: Attribute, data: RecordData, rule: AttributeConditionRule) {
        if (!target) {
            // Attribute could not be found, or is not used by any question; rule is invalid
            return null;
        }
        return RuleEngine.evaluateRule(rule, target.type, data[target.path]);
    }

    private evaluateConditions(conditions: AttributeConditions, attributes: Attribute[], data: RecordData) {
        if (!(conditions && conditions.rules)) {
            return true;
        }

        for (const rule of conditions.rules) {

            const target = rule.path ? attributes.find(a => a.path === rule.path) : null;

            const result = this.evaluateRule(target, data, rule);

            if (result === null) {
                // Rule is invalid, ignore
                continue;
            }

            if (!result && !conditions.any) {
                // Conditions require all rules to hold, and this one failed
                return false;
            }
            if (result && conditions.any) {
                // Conditions require any rule to hold, and this one passed
                return true;
            }
        }

        // If we reach here, either:
        // - *all* rules are required and none failed: success
        // - *any* rule is required and none passed: failure
        return !conditions.any;
    }

    private evaluateQuestions() {
        this.questions().forEach(q => {
            const formValue = this.form().getRawValue();
            let isVisible = true;
            if (q.type !== null || q.questionType === 'child' || q.questionType === 'text' || q.questionType === 'association' || q.questionType === 'geometry') {
                isVisible = this.evaluateConditions(q.conditions, this.attributes(), formValue);
            }
            
            if (!isVisible) {
                if (q.path && (
                    q.type === 'multiselect' ||
                    q.type === 'attachment' ||
                    q.type === 'media' ||
                    q.questionType === 'association' ||
                    q.questionType === 'child'
                )) {
                    this.form().controls[q.path].setValue([], { emitEvent: false });
                } else if (q.path && q.questionType === 'slider') {
                    const min = q.config.min;
                    const max = q.config.max;
                    let defaultValue = parseFloat(q.config?.defaultValue) || 0;
                    if (defaultValue < min) {
                        defaultValue = min;
                    } else if (defaultValue > max) {
                        defaultValue = max;
                    }
                    this.form().controls[q.path].setValue(defaultValue, { emitEvent: false });
                } else if (q.path) {
                    this.form().controls[q.path].setValue(null, { emitEvent: false });
                }
                this.form().controls[q.path].disable({ emitEvent: false });
            } else {
                /** If this question is now visible, and it has an auto flag, fill it in */
                if ((q.type ==='date' || q.type === 'datetime') && q.config?.auto && !this.form().controls[q.path].value) {
                    switch (q.type) {
                        case 'datetime': {
                            const dateString = formatISO(new Date());
                            this.form().controls[q.path].setValue(dateString);
                            break;
                        }
                        case 'date': {
                            const dateString = formatISO(new Date(), { representation: 'date' });
                            this.form().controls[q.path].setValue(dateString);
                            break;
                        }
                        default: {
                            console.error('DO NOT KNOW HOW TO AUTO THIS TYPE');
                        }
                    }
                }
                this.form().controls[q.path].enable({ emitEvent: false });
            }
        });
    }

    get formRawValue() {
        return this.form().getRawValue()
    }

    get attributesInCurrentForm(): Attribute[] {
        /** Return 'live' attributes - ignore any hidden/conditional questions */
        return this.attributes().filter(a => {
            return (
                a.visible &&
                !!this.form().controls[a.path] &&
                !this.form().controls[a.path].disabled
            );
        });
    }

    get formData(): { [path: string]: string } {
        /** Return form data excluding attachments and associations and text blocks */
        /** Unless attachments are the old style we need to add them back in at the end */
        const data = this.attributesInCurrentForm.filter(attribute => {
            return (
                attribute.type !== 'attachment' &&
                attribute.type !== 'media' &&
                attribute.questionType !== 'association' &&
                attribute.questionType !== 'child'
            );
        }).filter(attribute => {
            /** Check path is not null or undefined ie. text or thank you block */
            return !!attribute.path;
        }).filter(attribute => {
            /** Remove any fields with null or empty values */
            const value = this.formRawValue[attribute.path];
            if (value === null || value === undefined) {
                return false;
            } else if (typeof value === 'string') {
                return value.length > 0;
            } else if (typeof value ==='object') {
                return value.length > 0;
            } else {
                return true;
            }
        }).reduce((acc, val) => {
            let value = this.formRawValue[val.path];
            return {
                ...acc,
                [val.path]: value
            }
        }, {});

        /** Add old attachment data back in and handle any removing (add new attachments shouldn't be affected) */
        this.oldAttachments().forEach(a => {
            let value = a.url;
            let removed = this.attachmentsToRemove.map(r => r.path);
            if (removed.includes(a.path)) {
                value = null;
            }
            data[a.path] = value;
        });

        return data;
    }

    get newAttachments(): RecordAttachment[] {
        /** Return any new attachments from the form */
        return this.attributesInCurrentForm.filter(attribute => {
            return (
                attribute.type === 'attachment' ||
                attribute.type === 'media'
            );
        }).map(attribute => {
            return this.formRawValue[attribute.path] ?? []
        }).flat().filter((a: RecordAttachment) => {
            //return !a.url.startsWith('https://coreo.s3');
            //  or
            return a.id === null; // new attachments will have id null
        });
    }

    get attachmentsToRemove(): { id: number; path: string }[] {
        /** Return any attachments from the original data that are no longer in the form */
        return this.attributesInCurrentForm.filter(attribute => {
            return (
                attribute.type === 'attachment' ||
                attribute.type === 'media'
            );
        }).map(attribute => {
            /** compare difference between form and original attachments */
            let originalAttachments = [];
            if (this.data() && this.data()[attribute.path]) {
                originalAttachments = this.data()[attribute.path] ?? [];
            }
            const formAttachments: RecordAttachment[] = this.formRawValue[attribute.path] ?? [];
            /** return ids and paths of originalAttachments not in formAttachments then flatten to leave array of ids and paths to remove */
            return originalAttachments.filter(oa => !formAttachments.find(fa => fa.id === oa.id)).map(a => ({ id: a.id, path: attribute.path}));
        }).flat();
    }

    get newAssociates(): { id: number; attributeId: number }[] {
        /** Return any new associates from the form */
        return this.attributesInCurrentForm.filter(attribute => {
            return (
                /** we only want associates from lookups here will handle new child records separately */
                attribute.questionType === 'association'
            );
        }).map(attribute => {
            // compare difference between form and original associates
            let originalAssociates = [];
            if (this.data() && this.data()[attribute.path]) {
                originalAssociates = this.data()[attribute.path] ?? [];
            }
            const formAssociates = this.formRawValue[attribute.path] ?? [];
            // return ids of formAssociates not in originalAssociates then flatten to leave array of ids to remove
            return formAssociates.filter(fa => !originalAssociates.find(oa => oa === fa)).map(fa => ({ id: fa, attributeId: attribute.id }));
        }).flat();
    }

    get associatesToRemove(): { id: number; attributeId: number }[] {
        /** Return associates removed from the form */
        return this.attributesInCurrentForm.filter(attribute => {
            return (
                attribute.questionType === 'association' ||
                attribute.questionType === 'child'
            );
        }).map(attribute => {
            // compare difference between form and original associates
            let originalAssociates = [];
            if (this.data() && this.data()[attribute.path]) {
                originalAssociates = this.data()[attribute.path] ?? [];
            }
            const formAssociates = this.formRawValue[attribute.path] ?? [];

            // return ids of originalAssociates not in formAssociates then flatten to leave array of id to remove
            return originalAssociates.filter(oa => !formAssociates.find(fa => fa === oa)).map(oa => ({ id: oa, attributeId: attribute.id }));
        }).flat();
    }

    get childRecords(): NewChildRecord[] {
        return this.attributesInCurrentForm.filter(attribute => {
            return (
                // we only want child records
                attribute.questionType === 'child'
            );
        }).map(attribute => {
            const formAssociates = this.formRawValue[attribute.path] ?? [];
            const newChildRecords: NewChildRecord[] = formAssociates.filter(associate => {
                // filter for new child records only
                return typeof associate === 'object';
            });

            return newChildRecords;
        }).flat();
    }

    get geometryValid(): boolean {
        if (this.hasGeometryQuestion() && this.geometryRequired()) {
            if (this.recordMap().touched()) {
                return !!this.recordMap().updatedGeometry;
            } else {
                return !!this.recordMap().feature;
            }
        } else {
            return true;
        }
    }

    get updatedGeometry() {
        if (this.hasGeometryQuestion()) {
            return this.recordMap().updatedGeometry();
        } else {
            return undefined;
        }
    }

    get recordUpdateDataValid() {
        return this.form() && this.form().valid;
    }

    get formRecordUpdateData(): RecordUpdateData {
        return {
            id: this.recordId(),
            data: this.formData,
            deleteAssociates: this.associatesToRemove,
            newAssociates: this.newAssociates,
            childRecords: this.childRecords,
            deleteAttachments: this.attachmentsToRemove.filter(a => a.id !== 0).map(a => ({ id: a.id })), // filter out old style attachments which have id: 0, return just id (remove path)
            newAttachments: this.newAttachments
        };
    }

    get json() {
        return JSON.stringify(this.form().getRawValue());
    }

    isHidden(q: Question) {
        return !q.visible || this.form().controls[q.path].disabled;
    }
}