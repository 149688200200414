import { Component, OnInit, inject, signal, ChangeDetectionStrategy, Input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { ConfirmationService, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { PanelModule } from 'primeng/panel';
import { ToastModule } from 'primeng/toast';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { DragDropModule } from 'primeng/dragdrop';

import { Observable, map, tap } from 'rxjs';
import { ApiService } from 'src/app/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { DialogService } from 'primeng/dynamicdialog';
import { Enable2FAComponent } from '../enable-2fa/enable-2fa.component';
import { AuthService } from '../auth.service';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { RegenerateRecoveryCodesComponent } from '../regenerate-recovery-codes/regenerate-recovery-codes.component';
import { UploadProfileImageComponent } from './upload-profile-image.component';

interface UserProfileUpdateInput {
    username?: string;
    displayName?: string;
    image?: string;
}

type UserProfile = {
    displayName: string;
    username: string;
    email: string;
    role: string;
    imageUrl: string;
    mfaEnabled: boolean;
}


@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    standalone: true,
    imports: [
        ButtonModule,
        AvatarGroupModule,
        AvatarModule,
        ToastModule,
        PanelModule,
        DialogModule,
        DragDropModule,
        InputTextModule,
        ReactiveFormsModule,
        ConfirmDialogModule,
        UploadProfileImageComponent
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [MessageService, DialogService, ConfirmationService],
    styles: [`
    :host { @apply w-full h-full flex items-center justify-center; }
    `]
})
export class ProfileComponent implements OnInit {

    @Input() organisations: any[];

    apiService = inject(ApiService);
    formBuilder = inject(FormBuilder);
    messageService = inject(MessageService);
    dialogService = inject(DialogService);
    confirmationService = inject(ConfirmationService);
    auth = inject(AuthService);

    profileForm: FormGroup;
    imageUrl: string | ArrayBuffer | null = null;
    isVisibleUploadImageDialog = signal<boolean>(false);

    profile = signal<UserProfile | null>(null);

    readonly updateProfileQuery = `mutation updateUserInfo($input: UpdateProfileInputType!) {
            updateProfile(input: $input) {
                user {
                    username
                    displayName
                    imageUrl
                }
            }
    }`;

    readonly profileQuery = `query AAGetProfile {
        viewer {
            displayName
            username
            email
            role
            imageUrl,
            mfaEnabled 
        }
    }`;

    ngOnInit() {
        this.profileForm = this.formBuilder.group({
            displayName: ['', Validators.required],
            username: ['', Validators.required],
            image: [null]
        });

        this.apiService.graphql<{ viewer: UserProfile }>(this.profileQuery).pipe(
            map(res => res.viewer)
        ).subscribe(profile => {
            this.profile.set(profile);
            this.profileForm.patchValue({
                displayName: profile.displayName,
                username: profile.username
            });
            this.imageUrl = profile.imageUrl || null;
        });
    }

    updateUserInfo(input: Partial<UserProfileUpdateInput>): Observable<{ user: { username: string, displayName: string } }> {
        return this.apiService.graphql<{ updateProfile: { user: { username: string, displayName: string } } }>(
            this.updateProfileQuery,
            { input }
        ).pipe(
            map(response => {
                // console.log('Response from updateProfile mutation:', response.updateProfile.user);
                return { user: response.updateProfile.user };
            }),
            tap({
                next: (user) => {
                    console.log('Update successful for user:', user.user.username ?? 'No Username Update', user.user.displayName ?? 'No DisplayName Update');
                },
                error: (error) => {
                    console.error('Error updating user info:', error);
                }
            })
        );
    };

    showSuccess() {
        this.messageService.add({ severity: 'success', detail: 'Profile Updated' });
    };

    showError() {
        this.messageService.add({ severity: 'error', detail: 'Something went wrong on our end. Please try again later.' });
    }

    onSubmit() {
        if (!this.profileForm.valid) {
            return;
        }

        const file: File = this.profileForm.get('image')?.value;
        const variables = {
            input: {
                username: this.profileForm.value.username,
                displayName: this.profileForm.value.displayName,
            }
        };

        let files: { [key: string]: File } = {};
        if (file) {
            files = { 'imageUrl': file };
        }

        this.apiService.graphql<any>(
            this.updateProfileQuery,
            variables,
            files
        ).subscribe({
            next: () => {
                this.profileForm.markAsPristine();
                this.profileForm.markAsUntouched();
                // console.log('User info updated successfully-1', response); 
                this.showSuccess()
            },
            error: () => {
                // console.error('Error updating profile:', error);
                this.showError();
            }
        });
    }

    enable2FA() {
        this.dialogService.open(Enable2FAComponent, {
            header: 'Enable Two-Factor Authentication',
            width: '803px',
            closable: false
        }).onClose.subscribe((result) => {
            if (result) {
                this.messageService.add({ severity: 'success', summary: 'Success', detail: '2FA enabled successfully' });
                this.profile.update(profile => ({ ...profile, mfaEnabled: true }));
            }
        });
    }

    disable2FA($event: Event) {
        this.confirmationService.confirm({
            defaultFocus: 'none',
            message: 'Are you sure you want to disable Two-Factor Authentication?.',
            target: $event.target,
            header: 'Disable',
            rejectLabel: 'Cancel',
            rejectIcon: 'none',
            rejectButtonStyleClass: 'p-button p-button-lg p-button-outlined',
            acceptLabel: 'Disable Two-Factor Authentication',
            acceptIcon: 'none',
            acceptButtonStyleClass: 'p-button p-button-lg p-button-danger',
            accept: () => {
                this.auth.disable2FA().subscribe(() => {
                    this.messageService.add({ severity: 'success', summary: 'Success', detail: '2FA disabled successfully' });
                    this.profile.update(profile => ({ ...profile, mfaEnabled: false }));
                });
            }
        });
    }

    regenerateRecoveryCodes($event: Event) {
        $event.preventDefault();
        $event.stopPropagation();
        this.dialogService.open(RegenerateRecoveryCodesComponent, {
            header: 'Regenerate recovery codes',
            width: '803px',
            closable: false
        }).onClose.subscribe((result) => {
            if (result) {
                this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Codes regenerated successfully' });
            }
        });
    }

    onVisibleUploadImage(event: { isVisibleUploadImageDialog: boolean, imageUrl: string | null }) {
        const { imageUrl, isVisibleUploadImageDialog } = event;
        this.isVisibleUploadImageDialog.set(isVisibleUploadImageDialog);
        if (imageUrl) {
            this.imageUrl = imageUrl;
            this.profileForm.get('image').markAsTouched();
            this.onSubmit();
        };
    }
}
