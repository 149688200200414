<p-toast position="bottom-center" [life]="2000"></p-toast>
@if (recordData(); as record) {
    <div class="z-0 h-full w-full grid grid-rows-[64px_64px_1fr] overflow-hidden">
        <!-- top bar -->
        <div class="h-16 px-5 flex items-center bg-white border-b border-primary">
            <div class="flex items-center justify-start gap-4">
                <h2 class="text-xl font-semibold leading-none">{{ record.title }}</h2>
                <p class="text-sm font-semibold leading-none text-text-primary pl-4 border-l border-text-primary">{{ record.surveyName }}</p>
            </div>
            <div class="flex items-center justify-end gap-2">
                <!-- status dropdown -->
                @if (canEdit()) {
                    <p-dropdown [options]="verificationStates()" optionLabel="name" [ngModel]="verificationState()" (onChange)="verificationSateChanged($event)" [disabled]="stateLoading()">
                    </p-dropdown>
                }
                <!-- settings button -->
                @if (settingsMenuItems().length > 0) {
                    <p-menu #settingsMenu [model]="settingsMenuItems()" [popup]="true" appendTo="body"></p-menu>
                    <p-button [text]="true" (click)="settingsMenu.toggle($event)">
                        <ng-template pTemplate="buttonicon">
                            <img alt="settings icon" src="/assets/icons/cog-primary.svg" />
                        </ng-template>
                    </p-button>
                }
            </div>
        </div>
        <div class="h-16 px-5 flex items-center justify-between">
            <!-- breadcrumb -->
             <app-record-breadcrumb [recordId]="record.id" [recordTitle]="record.title" (record)="gotToRecord($event)"></app-record-breadcrumb>
            <!-- meta data -->
            <div class="flex items-center justify-end">
                @if (record.createdAt) {
                    <p class="text-xs font-normal text-text-primary px-4 border-r-2 border-grey-40 whitespace-nowrap">Created: <span class="font-semibold">{{ record.createdAt | date: 'd MMMM y' }}</span></p>
                }
                @if (updatedAt(); as updatedAt) {
                    @if (updatedAt && updatedAt !== record.createdAt) {
                        <p class="text-xs font-normal text-text-primary px-4 border-r-2 border-grey-40 whitespace-nowrap">Updated: <span class="font-semibold">{{ updatedAt | date: 'd MMMM y' }}</span></p>
                    }
                }
                <!-- user -->
                <div class="flex-none flex items-center gap-1 pl-4">
                    @if (record.user.imageUrl) {
                        <p-avatar [image]="record.user.imageUrl" shape="circle" styleClass="h-5 w-5 text-xs"></p-avatar>
                    }
                    @else {
                        <p-avatar [label]="record.user.displayName.charAt(0).toUpperCase()" shape="circle" styleClass="h-5 w-5 text-xs"></p-avatar>
                    }
                    <p class="text-xs font-semibold text-text-primary whitespace-nowrap">{{ record.user.displayName }}</p>
                </div>
            </div>
        </div>
        <div class="h-full w-full grid grid-cols-[1fr_1fr_320px] xl:grid-cols-[1fr_1fr_440px] gap-5 px-5 pb-5 overflow-hidden">
            <!-- main info -->
            <div class="relative col-span-2 h-full flex flex-col rounded-lg bg-white overflow-y-auto shadow-panel coreo-scrollbar">
                <!-- sticky header -->
                <div class="sticky top-0 z-50 h-16 p-4 flex-none flex items-center justify-between rounded-t-lg bg-white border-b border-grey-15">
                    <div class="flex items-center gap-3">
                        <img src="/assets/icons/clipboard-list-light.svg" alt="" class="h-5">
                        @if (editRecord()) {
                            <h3 class="text-lg font-semibold leading-none">Edit Data</h3>
                        }
                        @else {
                            <h3 class="text-lg font-semibold leading-none">Record Data</h3>
                        }
                    </div>
                    @if (canEdit()) {
                        @if (editRecord()) {
                            <div class="flex items-center justify-end gap-3">
                                <p-button [disabled]="submittingRecord()" label="Cancel" [outlined]="true" (click)="editRecord.set(false)" styleClass="w-32"></p-button>
                                <p-button [disabled]="submittingRecord() || !editReady() || !canSave()" [loading]="submittingRecord()" (click)="saveRecord($event)" styleClass="w-32 flex items-center justify-center">Save</p-button>
                            </div>
                        }
                        @else {
                            <p-button [disabled]="submittingRecord()" label="Edit data" (click)="editRecord.set(true)" styleClass="w-32">
                                <img alt="" src="/assets/icons/edit-white.svg" class="mr-1" />
                            </p-button>
                        }
                    }
                </div>
                @if (editRecord()) {
                    <app-record-form 
                        #recordForm
                        [projectId]="record.projectId"
                        [surveyId]="record.surveyId"
                        [isEdit]="true"
                        [recordId]="record.id"
                        [mapStyleName]="mapStyleName()"
                        (onMapStyleChanged)="mapStyleName.set($event)"
                    ></app-record-form>
                }
                @else {
                    <app-record-summary 
                        #recordSummary
                        [recordId]="record.id"
                        (record)="gotToRecord($event)"
                        [mapStyleName]="mapStyleName()"
                        (onMapStyleChanged)="mapStyleName.set($event)"
                    ></app-record-summary>
                }
            </div>
            <!-- activity & comments -->
            <div class="col-span-1 h-full w-full rounded-lg bg-white overflow-hidden shadow-panel">
                <!-- Activity logs are only available to admins or moderators so hide if not one of them -->
                @if (showActivityTabs()) {
                    <div class="h-[64px] p-1 flex border-b border-grey-30">
                        @for (tab of tabs(); track tab) {
                            <div (click)="activeTab.set(tab)" [ngClass]="{'flex items-center justify-center border-b-[5px] text-sm font font-semibold uppercase transition-colors hover:cursor-pointer': true, 'border-primary text-primary': activeTab() === tab, 'border-white text-text-secondary': activeTab() !== tab}">
                                <span class="">{{ tab }}</span>
                            </div>
                        }
                    </div>
                    @if (activeTab() === 'activity') {
                        <app-record-activity #activityTab [recordId]="record.id" [verificationStateMap]="verificationStateMap()" (recordReverted)="loadRecord()"></app-record-activity>
                    }
                    @else if (activeTab() === 'comments') {
                        <app-record-comments [recordId]="record.id" [projectId]="record.projectId" [viewer]="viewer()"></app-record-comments>
                    }
                    @else if (activeTab() === 'messages') {
                        <app-record-messages [recordId]="record.id" [projectId]="record.projectId" [viewer]="viewer()"></app-record-messages>
                    }
                }
                @else {
                    <div class="h-[64px] p-1 flex border-b border-grey-30">
                        <div class="flex items-center justify-center border-b-[5px] text-sm font font-semibold uppercase border-primary text-primary">
                            <span>Comments</span>
                        </div>
                    </div>
                    <app-record-comments [recordId]="record.id" [projectId]="record.projectId" [viewer]="viewer()"></app-record-comments>
                }
            </div>
        </div>
        <p-confirmDialog></p-confirmDialog>
    </div>
}
@else {
    <div class="relative h-full w-full">
        <div class="absolute inset-0 flex items-center justify-center bg-white bg-opacity-50">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
}